<template>
<div class="flex w-full vx-row no-gutter flex-wrap items-end justify-center login-main" id="page-login">
    <span class="top-bg-overly"><img src="../../assets/images/login-left.png" /></span>
    <div class="container">
        <div class="vx-row justify-center">
            <div class="vx-col md:w-3/4 w-full">
                <div class="vx-card__title login-title">
                    <h2>
                        <span>Welcome to</span> <br />
                        Visual Visitor.
                    </h2>
                </div>
            </div>
        </div>
        <div class="vx-row justify-center">
            <div class="vx-col login-form-info md:w-3/4 w-full">
                <vx-card>
                    <div class="vx-row justify-center items-center">
                        <!-- Login Image -->
                        <div class="vx-col hidden lg:block lg:w-7/12 order-2">
                            <img src="@/assets/images/login-right.svg" alt="login" class="mx-auto login-img" />
                        </div>
                        <!-- Login Form -->
                        <div class="vx-col w-full lg:w-5/12 d-theme-dark-bg order-1">
                            <div class="p-4 login-tabs-container">
                                <div class="vx-card__title mb-2">
                                    <h4>Login Verification</h4>
                                </div>
                                <div>
                                    <form data-vv-scope="loginForm" class="label_loginscreen">
                                        <div>
                                            <span class="vs-input--label" v-html="varificationCodeMessage ? varificationCodeMessage : 'Enter the verification code sent to your email'"></span>
                                            <vs-input name="email" v-model="verficationCode" v-validate="'required'" class="w-full" />
                                            <span class="text-danger text-sm">{{
                          errors.first("email")
                        }}</span>
                                        </div>
                                        <div class="flex float-left flex-wrap justify-between mt-5">
                                            <vs-checkbox v-model="rememberMyDevice" class="mb-3" style="">Remember this device</vs-checkbox>

                                        </div>

                                    </form>
                                    <vs-button color="secondary" id="user-login" :disabled="button_loading" type="filled" class="w-full float-left mt-6" @click="twoFactorAuth()">Login</vs-button>
                                    <div class="flex float-left flex-wrap justify-between mt-5" @click="resendVerification()">
                                        <a href="#">Resend Verification Code</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </vx-card>
                <router-link to="Login" class="forgot-pass">Return to Login <feather-icon icon="ArrowRightIcon"></feather-icon>
                </router-link>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    Validator
} from "vee-validate";
import CryptoJS from "crypto-js"
import constantKey from '../../constants/constant-key.json';
import Cookie from 'js-cookie'
import store from "../../store/store";

const dict = {
    custom: {
        email: {
            required: "Please enter valid verification code",
        },
    },
};

Validator.localize("en", dict);

export default {
    data() {
        return {
            verficationCode: null,
            rememberMyDevice: false,
            email: null,
            password: null,
            decryptUserInfo: "",
            plainUserInfo: "",
            interval: null,
            varificationCodeMessage: null,
        };
    },
    created() {
        this.varificationCodeMessage = this.$route.params.message
        this.interval = setInterval(() => this.checkAppVersion(), 1000 * 300 * 1);
        let decryptUserInfo = CryptoJS.AES.decrypt(localStorage.getItem('VVUserInfo'), constantKey.VV_SECRET_KEY);
        let plainUserInfo = JSON.parse(decryptUserInfo.toString(CryptoJS.enc.Utf8));
        this.email = plainUserInfo[0].userEmail;
        this.password = plainUserInfo[0].userPass;
    },
    mounted() {
        this.setAppVersion();
    },
    methods: {
        async twoFactorAuth() {

            let loginParms = {
                Email: this.email,
                Password: this.password,
                Code: this.verficationCode,
                RememberDevice: this.rememberMyDevice
            }

            await this.axios
                .post("Account/ValidateChallenge", loginParms)
                .then((response) => {
                    let data = response.data;
                    if (data.IsSuccess == true) {
                        if (this.rememberMyDevice) {
                            let message = CryptoJS.AES.encrypt(JSON.stringify(data.Message), constantKey.VV_SECRET_KEY).toString();
                            Cookie.set("VVRememberMyDevice", message, {
                                expires: 180
                            });
                        } else {
                            Cookie.remove("VVRememberMyDevice");
                        }

                        let url = "Account/GenerateTokenV3Json";
                        this.loginErrorMessage = null;
                        let loginParams = {
                            Email: this.email,
                            Password: this.password,
                            CT: data.Message
                        }
                        this.axios
                            .post(url, loginParams)
                            .then(response => {
                                let tokenGeneratedAt = new Date();
                                tokenGeneratedAt.setHours(tokenGeneratedAt.getHours() + 1);
                                Cookie.set("VVtokenGeneratedAt", tokenGeneratedAt);

                                let data = response.data;
                                this.button_loader(false, "#user-login");
                                let token = [];
                                token.push({
                                    id: "primary",
                                    token: data.token,
                                    refreshToken: data.refreshToken,
                                    vendastaIsPartner: false,
                                    vendastaAccountId: '',
                                    vendastaProductId: '',
                                    vendastaThirdpartyNavbarUrl: '',
                                    isLogedIn: true,
                                    menuItems: null,
                                    themeDetail: null,
                                    isReseller: null,
                                    isActiveFreeTrial: null,
                                    isExpiredFreeTrial: null,
                                    resellerIsLegacyOnly: null,
                                    ShowContactReport: null,
                                    userDetails: null,
                                    maxLocalNumbers: null,
                                });

                                localStorage.setItem("VVtoken", JSON.stringify(token));
                                this.$store.dispatch("updateAuthToken", token);

                                this.$gtag.event('login', {
                                    method: 'Google'
                                })

                                let url = "Account/GetInterfaceOptions";
                                this.axios.defaults.headers.common["Authorization"] =
                                    "Bearer " + data.token;
                                this.axios
                                    .get(url)
                                    .then(response => {
                                        let data = response.data;
                                        let menuItem = data.MenuItems;
                                        let themeDetail = data.DashboardThemeV2;
                                        themeDetail.tempActionButtonColor =
                                            this.RGBToHex(data.DashboardThemeV2.ActionButtonColor);
                                        themeDetail.tempChartColor1 =
                                            this.RGBToHex(data.DashboardThemeV2.ChartColor1);
                                        themeDetail.tempChartColor2 =
                                            this.RGBToHex(data.DashboardThemeV2.ChartColor2);
                                        themeDetail.tempChartColor3 =
                                            this.RGBToHex(data.DashboardThemeV2.ChartColor3);
                                        themeDetail.tempChartColor4 =
                                            this.RGBToHex(data.DashboardThemeV2.ChartColor4);
                                        themeDetail.tempChartColor5 =
                                            this.RGBToHex(data.DashboardThemeV2.ChartColor5);
                                        themeDetail.tempChartColor6 =
                                            this.RGBToHex(data.DashboardThemeV2.ChartColor6);
                                        themeDetail.tempChartColor7 =
                                            this.RGBToHex(data.DashboardThemeV2.ChartColor7);
                                        themeDetail.tempChartColor8 =
                                            this.RGBToHex(data.DashboardThemeV2.ChartColor8);
                                        themeDetail.tempFirstColor =
                                            this.RGBToHex(data.DashboardThemeV2.FirstColor);
                                        themeDetail.tempSecondColor =
                                            this.RGBToHex(data.DashboardThemeV2.SecondColor);
                                        themeDetail.tempThirdColor =
                                            this.RGBToHex(data.DashboardThemeV2.ThirdColor);
                                        themeDetail.tempFourthColor =
                                            this.RGBToHex(data.DashboardThemeV2.FourthColor);
                                        themeDetail.tempNavbarColor =
                                            this.RGBToHex(data.DashboardThemeV2.NavbarColor);
                                        themeDetail.tempThemeColor =
                                            this.RGBToHex(data.DashboardThemeV2.ThemeColor);
                                        let isReseller = data.Reseller;
                                        let isActiveFreeTrial = data.IsActiveFreeTrial;
                                        let isExpiredFreeTrial = data.FreeTrialIsExpired;
                                        let resellerIsLegacyOnly = data.Reseller_Is_Legacy_Only;
                                        let ShowContactReport = data.ShowContactReport;
                                        let userDetails = data.UserInformation;
                                        let maxLocalNumbers = data.MaxLocalNumbers;
                                        let DefaultHomePage = data.DefaultHomePage;
                                        let showBillingV2 = data.ShowBillingV2;
                                        let showGuidedTour = data.ShowGuidedTour;
                                        let isConsumerPlan = data.Is_Consumer_Plan;
                                        let isReportSettingsReadOnly = data.Is_Report_Settings_ReadOnly;
                                        let screenItemsVisiblity = data.ScreenItems;
                                        let enableZapierIntegration = data.EnableZapierIntegration;
                                        let isResellerPrimaryAccount = data.Is_Reseller_Primary_Account;
                                        let isResellerSubaccount = data.Is_Reseller_Subaccount;

                                        let VVtoken = localStorage.getItem("VVtoken");
                                        let token = JSON.parse(VVtoken);
                                        token[0].menuItems = menuItem;
                                        token[0].themeDetail = themeDetail;
                                        token[0].isReseller = isReseller;
                                        token[0].isActiveFreeTrial = isActiveFreeTrial;
                                        token[0].isExpiredFreeTrial = isExpiredFreeTrial;
                                        token[0].resellerIsLegacyOnly = resellerIsLegacyOnly;
                                        token[0].ShowContactReport = ShowContactReport;
                                        token[0].userDetails = userDetails;
                                        token[0].maxLocalNumbers = maxLocalNumbers;
                                        token[0].DefaultHomePage = DefaultHomePage;
                                        token[0].showBillingV2 = showBillingV2;
                                        token[0].showGuidedTour = showGuidedTour;
                                        token[0].screenItems = screenItemsVisiblity;
                                        token[0].isConsumerPlan = isConsumerPlan;
                                        token[0].isReportSettingsReadOnly = isReportSettingsReadOnly;
                                        token[0].enableZapierIntegration = enableZapierIntegration;
                                        token[0].isResellerPrimaryAccount = isResellerPrimaryAccount;
                                        token[0].isResellerSubaccount = isResellerSubaccount;

                                        localStorage.setItem("VVtoken", JSON.stringify(token));
                                        store.dispatch("updateAuthToken", token);

                                        localStorage.removeItem("VVUserInfo");

                                        clearInterval(this.interval);

                                        let redirectDefaultUrl = this.getRedirectDefaultUrl(data.DefaultHomePage)
                                        this.$router.push(redirectDefaultUrl + 0);

                                    })
                                    .catch((e) => {
                                         this.showError(e);
                                        this.logout();
                                    });

                            })
                            .catch(e => {
                                this.showError(e);
                            });

                    } else {
                        this.$vs.notify({
                            title: "Invalid Token",
                            text: "The verification token is no longer valid",
                            color: "danger",
                            iconPack: "feather",
                            icon: "icon-alert-triangle",
                            position: "top-right",
                            time: 4000
                        });

                    }
                }).catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);

                });
        },
        resendVerification() {

            let resendParams = {
                Email: this.email,
                Password: this.password
            }
            this.$vs.loading();
            this.axios
                .post('/Account/SendVerificationCode', resendParams).then(() => {
                    this.$vs.notify({
                        title: "Success",
                        text: "The verification code was sent to your mail",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                }).catch(e =>{
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Invalid",
                        text: "Please enter valid email and password",
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-triangle",
                        position: "top-right",
                        time: 4000
                    });

                })
        },
        setAppVersion() {
            this.axios.get('/Account/GetCurrentVersion').then(response => {
                let data = response.data;
                this.$store.commit('APP_VERSION', data)
            }).catch(e => {
                this.showError(e);
            });
        },
        checkAppVersion() {
            this.axios.get('/Account/GetCurrentVersion').then(response => {
                let data = response.data;
                if (data != this.$store.state.appVersion) {
                    window.location.reload();
                }

            }).catch(e => {
                this.showError(e);
            });
        },
    },

};
</script>

<style lang="scss">
input::-webkit-input-placeholder {
    color: rgba(var(--vs-primary), 1);
    opacity: 1;
}

input:-ms-input-placeholder {
    color: rgba(var(--vs-primary), 1);
    opacity: 1;
}

input::placeholder {
    color: rgba(var(--vs-primary), 1);
    opacity: 1;
}

.input-span-placeholder {
    height: 100%;
    display: flex;
    align-items: center;
}

.login-form {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    background: #ffffff;
    z-index: 1;
    padding: 32px 0px;

    .login-content {
        float: left;
        width: calc(100% - 10%);
        margin-left: 10%;
        background: #ffffff;
        box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.15);
        border-radius: 1em;
        padding: 32px 48px;
        position: relative;

        &:after {
            position: absolute;
            left: -100px;
            top: 32px;
            height: 212px;
            width: 212px;
            border-radius: 100%;
            background: rgba(205, 205, 205, 0.15);
            content: "";
            z-index: -1;
        }

        h1 {
            float: left;
            width: 100%;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 30px;
        }

        .vs-input--label {
            padding-left: 0px;
            font-weight: 500;
            color: #666666;
            margin-bottom: 8px;
            float: left;
            width: 100%;
        }

        .vs-con-input {
            float: left;
            width: 100%;

            .vs-input--input {
                border: 0px !important;
                border-radius: 8px !important;
                background: rgba(var(--vs-primary), 0.1);
                color: rgba(var(--vs-primary), 1);
                font-weight: 500;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .vs-input--icon.icon-after {
            border: 0px;
            font-weight: 600;
            color: rgba(var(--vs-primary), 1);
        }

        .con-slot-label {
            font-size: 12px;
            color: #333;
            font-weight: 500;
            margin-left: 4px;
        }

        .vs-button {
            border-radius: 8px;
            padding: 6px 30px;
        }

        .login-icon {
            background: rgba(var(--vs-primary), 1);
            width: 56px;
            height: 56px;
            border-radius: 100%;
            float: left;
            text-align: center;
            position: absolute;
            left: -30px;
            top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            box-shadow: 0px 0px 10px 2px rgba(var(--vs-primary), 0.2);

            img {
                max-width: 22px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    .logo-login {
        float: left;
        width: 100%;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        .dashboard-img {
            width: calc(100% - 14%);
            background: #ffffff;
            border-radius: 0.4em;
            float: right;
            margin-left: 14%;
        }

        .top-circles {
            position: absolute;
            left: 35%;
            top: -60px;
            width: 70px;
            height: 70px;
            background: rgba(var(--vs-primary), 0.1);
            content: "";
            border-radius: 100%;

            &:after {
                position: absolute;
                left: -13px;
                bottom: -1px;
                width: 40px;
                height: 40px;
                background: rgba(205, 205, 205, 0.3);
                content: "";
                border-radius: 100%;
            }
        }

        h5 {
            position: absolute;
            right: -25%;
            bottom: -90px;
            font-size: 140px;
            text-transform: capitalize;
            font-weight: 700;
            color: rgba(205, 205, 205, 0.2);

            &:after {
                position: absolute;
                left: 111px;
                top: 76px;
                width: 38px;
                height: 40px;
                background: rgba(var(--vs-primary), 1);
                content: "";
                border-radius: 100%;
                box-shadow: 0px 15px 32px 8px rgba(var(--vs-primary), 0.3);
            }
        }
    }
}

@media (max-width: 1199px) {
    .login-form .logo-login {
        display: none;
    }

    .login-form .login-content {
        margin-left: 100px;
        width: calc(100% - 100px);
    }
}

@media (max-width: 991px) {}

@media (max-width: 575px) {
    .login-form .login-content {
        padding: 32px;
    }
}

@media (max-width: 480px) {
    .login-form .login-content {
        margin-left: 0px;
        width: calc(100% - 0px);
        margin-top: 32px;
        padding-top: 56px;
    }

    .login-form .login-content .login-icon {
        right: 0px;
        left: 0px;
        top: -30px;
        margin: auto;
    }
}
</style>
